'use client'

import { ReactNode } from 'react'
import { Text, Dialog, Button, Navigation, Cell } from '@vinted/web-ui'
import { ArrowLeft24 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import FaqEntryUrl from 'components/FaqEntryUrl'
import ScrollableArea from 'components/ScrollableArea'

import { AccessChannel } from 'constants/index'
import { FaqEntryType } from 'constants/faq-entry'

type Props = {
  show: boolean
  onBack: () => void
}

const ClosetPromotionPreCheckoutHelpModal = ({ show, onBack }: Props) => {
  const translate = useTranslate('closet_promotion.pre_checkout_help_modal')
  const a11yTranslate = useTranslate('closet_promotion.a11y.actions')

  function renderInfoBlock(type: string) {
    return (
      <Cell
        title={<Text as="h2" text={translate(`${type}.title`)} type={Text.Type.Title} />}
        body={<Text as="span" text={translate(`${type}.body`)} html />}
      />
    )
  }

  function renderFaqNote() {
    const values = {
      'help-center': (chunks: Array<ReactNode>) => (
        <FaqEntryUrl
          type={FaqEntryType.ClosetPromotion}
          accessChannel={AccessChannel.ProductLink}
          render={url => (
            <a href={url} target="_blank" rel="noreferrer">
              {chunks}
            </a>
          )}
        />
      ),
    }

    return (
      <Cell>
        <Text as="h3" html text={translate('read_more', values)} type={Text.Type.Subtitle} />
      </Cell>
    )
  }

  return (
    <Dialog show={show} hasScrollableContent>
      <div className="u-fill-width">
        <ScrollableArea>
          <Navigation
            left={
              <Button
                theme="amplified"
                iconName={ArrowLeft24}
                styling={Button.Styling.Flat}
                testId="closet-promotion-pre-checkout-help-back-button"
                onClick={onBack}
                aria={{ 'aria-label': a11yTranslate('back') }}
              />
            }
            body={<Text as="h2" text={translate('title')} type={Text.Type.Title} />}
          />
          {renderInfoBlock('item_selection')}
          {renderInfoBlock('featured')}
          {renderFaqNote()}
        </ScrollableArea>
      </div>
    </Dialog>
  )
}

export default ClosetPromotionPreCheckoutHelpModal
