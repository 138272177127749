'use client'

import { useEffect, useState } from 'react'
import { Dialog } from '@vinted/web-ui'

import useFetch from 'hooks/useFetch'
import useTracking from 'hooks/useTracking'
import useLocation from 'hooks/useLocation'

import { ExtraServiceOrderType, CheckoutOrderTypeMap } from 'constants/extra-service'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { UiState } from 'constants/ui'
import { PreCheckoutModal } from 'constants/closet-promotion'

import { navigateToSingleCheckout } from 'libs/utils/checkout'
import { clickEvent, viewSingleCheckoutEvent } from 'libs/common/event-tracker/events'
import { removeParamsFromQuery } from 'libs/utils/url'

import {
  getClosetPromotionPricing,
  initiateSingleCheckout,
  prepareClosetPromotionOrder,
} from 'data/api'
import {
  transformClosetPromotionPricingResponse,
  transformPrepareClosetPromotionOrderResponse,
} from 'data/api/transformers/response'

import ContentLoader from 'components/ContentLoader'
import GenericErrorModal from 'pages/SingleCheckout/components/GenericErrorModal'

import ClosetPromotionPreCheckoutModal from './ClosetPromotionPreCheckoutModal'
import ClosetPromotionPreCheckoutHelpModal from './ClosetPromotionPreCheckoutHelpModal'
import ClosetPromotionDynamicPricingInfoModal from './ClosetPromotionDynamicPricingInfoModal'

type Props = {
  isOpen: boolean
  handlePrecheckoutClose: () => void
}

const ClosetPromotionCheckout = ({ isOpen, handlePrecheckoutClose }: Props) => {
  const { track } = useTracking()
  const { cp_precheckout: closetPromotionPrecheckoutParam } = useLocation().searchParams
  const { replaceHistoryState, relativeUrl, urlQuery } = useLocation()

  const [uiState, setUiState] = useState(UiState.Idle)
  const [activeModal, setActiveModal] = useState<PreCheckoutModal>(PreCheckoutModal.None)

  const {
    fetch: fetchClosetPromotionPricing,
    transformedData: closetPromotionPricing,
    isLoading: isClosetPromotionPricingLoading,
    error: closetPromotionPricingError,
  } = useFetch(getClosetPromotionPricing, transformClosetPromotionPricingResponse)

  const {
    fetch: prepareOrder,
    transformedData: order,
    isLoading: isOrderLoading,
    error: orderError,
  } = useFetch(prepareClosetPromotionOrder, transformPrepareClosetPromotionOrderResponse)

  useEffect(() => {
    if (activeModal === PreCheckoutModal.PreCheckout && !closetPromotionPricing) {
      fetchClosetPromotionPricing()
    }
  }, [fetchClosetPromotionPricing, activeModal, closetPromotionPricing])

  useEffect(
    function startPreCheckoutFromParam() {
      if (closetPromotionPrecheckoutParam !== 'true') return

      setActiveModal(PreCheckoutModal.PreCheckout)
    },
    [closetPromotionPrecheckoutParam],
  )

  useEffect(
    function startPreCheckoutFromProp() {
      if (!isOpen) return

      setActiveModal(PreCheckoutModal.PreCheckout)
    },
    [isOpen],
  )

  useEffect(
    function setErrorModal() {
      if (!orderError && !closetPromotionPricingError) return

      setActiveModal(PreCheckoutModal.GenericErrorModal)
    },
    [orderError, closetPromotionPricingError],
  )

  useEffect(
    function startCheckoutAfterOrderPrepare() {
      if (!order) return

      track(
        clickEvent({
          screen: Screen.ClosetPromotionOrderReview,
          target: ClickableElement.ReviewClosetPromotionOrder,
          targetDetails: order.id.toString(),
        }),
      )

      const orderId = order?.id
      const orderType = CheckoutOrderTypeMap[ExtraServiceOrderType.ClosetPromotion]

      track(
        viewSingleCheckoutEvent({
          checkoutId: null,
          orderType,
          orderId: orderId.toString(),
        }),
      )

      setUiState(UiState.Pending)

      const fetchCheckoutId = async () => {
        const response = await initiateSingleCheckout({
          id: orderId.toString(),
          type: orderType,
        })

        if ('errors' in response) {
          setUiState(UiState.Failure)

          setActiveModal(PreCheckoutModal.GenericErrorModal)

          return
        }

        navigateToSingleCheckout(response?.checkout?.id, orderId, orderType)
      }

      fetchCheckoutId()
    },
    [order, track],
  )

  function openPreCheckoutModal() {
    setActiveModal(PreCheckoutModal.PreCheckout)
  }

  function openDynamicPricingInfoModal() {
    setActiveModal(PreCheckoutModal.DynamicPricingInfo)

    track(
      clickEvent({
        screen: Screen.ClosetPromotionOrderReview,
        target: ClickableElement.ClosetPromotionDynamicPricingInfo,
      }),
    )
  }

  function closeCheckoutModal() {
    if (closetPromotionPrecheckoutParam === 'true') {
      const urlWithoutParam = removeParamsFromQuery(relativeUrl, urlQuery, ['cp_precheckout'])
      replaceHistoryState(urlWithoutParam)
    }
    setActiveModal(PreCheckoutModal.None)
    handlePrecheckoutClose()
  }

  function handlePreCheckoutConfirm() {
    prepareOrder()
  }

  function handlePreCheckoutPreview() {
    track(
      clickEvent({
        screen: Screen.ClosetPromotionOrderReview,
        target: ClickableElement.ClosetPromotionPreCheckoutHelp,
      }),
    )

    setActiveModal(PreCheckoutModal.PreCheckoutHelp)
  }

  if (uiState === UiState.Pending) {
    return (
      <Dialog show>
        <ContentLoader testId="closet-promotion-checkout-loader" />
      </Dialog>
    )
  }

  return (
    <>
      <ClosetPromotionPreCheckoutModal
        pricing={closetPromotionPricing}
        isPricingLoading={isClosetPromotionPricingLoading}
        isOrderLoading={isOrderLoading}
        show={activeModal === PreCheckoutModal.PreCheckout}
        onNextAction={handlePreCheckoutConfirm}
        onPreview={handlePreCheckoutPreview}
        onDynamicPricingInfo={openDynamicPricingInfoModal}
        onBack={closeCheckoutModal}
      />
      <ClosetPromotionPreCheckoutHelpModal
        show={activeModal === PreCheckoutModal.PreCheckoutHelp}
        onBack={openPreCheckoutModal}
      />
      <ClosetPromotionDynamicPricingInfoModal
        show={activeModal === PreCheckoutModal.DynamicPricingInfo}
        onBack={openPreCheckoutModal}
      />
      <GenericErrorModal
        isShown={activeModal === PreCheckoutModal.GenericErrorModal}
        onClose={closeCheckoutModal}
      />
    </>
  )
}

export default ClosetPromotionCheckout
